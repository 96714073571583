/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bus-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 11a1 1 0 11-2 0 1 1 0 012 0m8 0a1 1 0 11-2 0 1 1 0 012 0m-6-1a1 1 0 100 2h2a1 1 0 100-2zm1-6c-1.876 0-3.426.109-4.552.226A.5.5 0 003 4.723v3.554a.5.5 0 00.448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0013 8.277V4.723a.5.5 0 00-.448-.497A44 44 0 008 4m0-1c-1.837 0-3.353.107-4.448.22a.5.5 0 11-.104-.994A44 44 0 018 2c1.876 0 3.426.109 4.552.226a.5.5 0 11-.104.994A43 43 0 008 3"/><path pid="1" d="M15 8a1 1 0 001-1V5a1 1 0 00-1-1V2.64c0-1.188-.845-2.232-2.064-2.372A44 44 0 008 0C5.9 0 4.208.136 3.064.268 1.845.408 1 1.452 1 2.64V4a1 1 0 00-1 1v2a1 1 0 001 1v3.5c0 .818.393 1.544 1 2v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V14h6v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2c.607-.456 1-1.182 1-2zM8 1c2.056 0 3.71.134 4.822.261.676.078 1.178.66 1.178 1.379v8.86a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 11.5V2.64c0-.72.502-1.301 1.178-1.379A43 43 0 018 1"/>',
    },
});
